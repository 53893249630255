import { useEffect, useState, createContext, useContext } from "react";
import { useHistory } from "react-router-dom";
import { usePagingSortingContext } from "./pagingSortingContext";
import { useBrowserStorage } from "./StorageService/StorageHelper";
import { STORAGE_NAMES, STORAGE_TYPE } from "../constants";

export const FacultyDemographicsContext = createContext();

export const initialFacultyDemographicsState = ({
  location,
  pageSort,
  filterValues,
}) => {
  pageSort = pageSort || {};
  const { filters, pagination, sort } = filterValues?.facultyDemographics ?? {};

  return {
    facultyDemographics: {
      sort: {
        orderBy: sort?.orderBy || "",
        order: sort?.order || "",
        ...pageSort.sort,
      },
      pagination: {
        page: pagination?.page || "",
        pageSize: pagination?.pageSize || "",
        ...pageSort.pagination,
      },
      delete: 0,
      addFaculty: 0,
      submission: 0,
      filters: {
        facultyName: filters?.facultyName || "",
        degreeCode: filters?.degreeCode || "",
        rankCode: filters?.rankCode || "",
        trainingRole: filters?.trainingRole || "",
        departmentCode: filters?.departmentCode || "",
      },
    },
  };
};

export const FacultyDemographicsProvider = ({ children }) => {
  const history = useHistory();
  const { facultyPagingSortingStored, setFacultyPagingSortingStored } =
    usePagingSortingContext().value;
  const { getItem, saveItem } = useBrowserStorage(STORAGE_TYPE.SESSION);
  const filterValues = getItem(
    STORAGE_NAMES.TGDS_FACULTY_DASHBOARD_FILTERS,
    Object
  );
  const [facultyPreference, setFacultyPreferenceInternal] = useState(
    initialFacultyDemographicsState({
      location: history.location,
      pageSort: facultyPagingSortingStored,
      filterValues,
    })
  );
  const [facultyFilterReload, setFacultyFilterReload] = useState(false);

  const setFacultyPreference = (preference) => {
    preference = preference || {};

    const locPageSort = {
      pagination: preference.facultyDemographics?.pagination || {},
      sort: preference.facultyDemographics?.sort || {},
    };

    setFacultyPagingSortingStored(locPageSort);
    setFacultyPreferenceInternal(preference);
    saveItem(STORAGE_NAMES.TGDS_FACULTY_DASHBOARD_FILTERS, preference);
  };

  useEffect(() => {
    setFacultyPagingSortingStored({
      pagination: facultyPreference.facultyDemographics?.pagination || {},
      sort: facultyPreference.facultyDemographics?.sort || {},
    });
  }, [setFacultyPagingSortingStored, facultyPreference]);

  const updatePreferencesForSubmission = (selectedSubmission) => {
    const initState = initialFacultyDemographicsState({});
    setFacultyPreference({
      ...initState,
      facultyDemographics: {
        ...initState.facultyDemographics,
        submission: selectedSubmission,
      },
    });
    setFacultyFilterReload(!facultyFilterReload);
  };

  return (
    <FacultyDemographicsContext.Provider
      value={{
        facultyPreference,
        setFacultyPreference,
        updatePreferencesForSubmission,
        facultyFilterReload,
      }}
    >
      {children}
    </FacultyDemographicsContext.Provider>
  );
};

export const useFacultyDemographicsContext = () => {
  return useContext(FacultyDemographicsContext);
};
