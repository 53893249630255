import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  Checkbox as MuiCheckbox,
  FormControl as MuiFormControl,
  FormControlLabel as MuiFormControlLabel,
  LinearProgress as MuiLinearProgress,
} from "@material-ui/core";
/** Custom Components */
import { Autocomplete } from "../../UI/Autocomplete/Autocomplete";
import { AutocompleteOption } from "../../../Utils/Autocomplete";
import { ResetFilter } from "../../Layout/FilterPanel/ResetFilter.js";
/** Static Dropdown Values */
import {
  traineeTypeDropDown,
  STORAGE_TYPE,
  STORAGE_NAMES,
} from "../../../constants.js";
/** Services */
import {
  useApplicantsFilterContext,
  useApplcantsFilterData,
} from "../../../services/applicantContext.js";
import { useSelectedSubmission } from "../../../services/selectedSubmissionService.js";
import { useBrowserStorage } from "../../../services/StorageService/StorageHelper";
import { useApplicantsSelectedContext } from "../../../services/applicantsSelectedContext";
/** Styles */
import { useStyles } from "./ApplicantsFilter.styles.js";
import { useCommonStyles } from "../../shared/common.styles";

/***************** Applicants Filter - Main Component *****************/

export const ApplicantsFilter = ({ changeFilterData, submissionUpdate }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { saveItem, getItem } = useBrowserStorage(STORAGE_TYPE.SESSION);
  const applicantsFiltersTranslationKeys = "applicants.mainView.filters";
  const { applicantsFilterPreferences, setApplicantsFilterPreferences } =
    useApplicantsFilterContext();

  const { resetAllSelections } = useApplicantsSelectedContext();

  const [getSelectedSubmission] = useSelectedSubmission().value;
  const selectedSubmission = getSelectedSubmission();
  const { filterData, loading } = useApplcantsFilterData(selectedSubmission);

  const classes = useStyles();
  const commonClasses = useCommonStyles();

  // Initial Form State
  const resetValues = {
    applicant: null,
    applicantName: "",
    applicantUniversityId: "",
    departmentName: "",
    departmentCode: "",
    applicationYear: "",
    acceptedYear: "",
    applicantType: "",
    newEntrant: "",
    grantEligible: "",
    isActive: true,
  };
  const savedPrefs = getItem(
    STORAGE_NAMES.TGDS_APPLICANT_DASHBOARD_FILTERS,
    Object
  );
  const filterPrefs = savedPrefs?.applicantValues?.filters;

  const initialValues = {
    applicant: filterPrefs?.applicant || resetValues.applicant,
    applicantName: filterPrefs?.applicantName || resetValues.applicantName,
    departmentName: filterPrefs?.departmentName || resetValues.departmentName,
    departmentCode: filterPrefs?.departmentCode || resetValues.departmentCode,
    applicationYear:
      filterPrefs?.applicationYear || resetValues.applicationYear,
    acceptedYear: filterPrefs?.acceptedYear || resetValues.acceptedYear,
    applicantType: filterPrefs?.applicantType || resetValues.applicantType,
    newEntrant: filterPrefs?.newEntrant || resetValues.newEntrant,
    grantEligible: filterPrefs?.grantEligible || resetValues.grantEligible,
    isActive: !!(filterPrefs?.isActive ?? true),
  };
  const [selectedValues, setSelectedValues] = useState(initialValues);

  const getFieldDisplayText = (optionsArray, field, valueId) =>
    optionsArray?.filter(
      (value) => selectedValues[field] === (valueId ? value[valueId] : value)
    )[0];
  const selectedProgram = getFieldDisplayText(
    filterData.departments,
    "departmentCode",
    "code"
  );

  // Reset Form Values
  const resetApplicantsFilter = () => {
    setApplicantsFilterPreferences({
      ...applicantsFilterPreferences,
      applicantValues: {
        ...applicantsFilterPreferences.applicantValues,
        filters: {
          ...applicantsFilterPreferences.applicantValues.filters,
          ...resetValues,
        },
        sort: {
          orderBy: "",
          order: "",
        },
        pagination: {
          page: "",
          pageSize: "",
        },
      },
    });
    changeFilterData(resetValues);
    setSelectedValues(resetValues);
    saveItem(STORAGE_NAMES.TGDS_APPLICANT_DASHBOARD_FILTERS, {
      applicantValues: { filters: resetValues },
    });
    history.push(`${history.location.pathname}`);
    resetAllSelections();
  };

  // On Dropdown Value change, update search results
  const handleFilterValueUpdate = (fieldName, fieldValue, updateFilterData) => {
    const updatedFilterValues = {
      ...selectedValues,
      [fieldName]: fieldValue,
    };

    if (updateFilterData) {
      changeFilterData(updatedFilterValues);
      setSelectedValues(updatedFilterValues);
    }
    resetAllSelections();
  };

  const handleOnChange = (filterName, value) => {
    handleFilterValueUpdate(filterName, value, true);
    setApplicantsFilterPreferences({
      ...applicantsFilterPreferences,
      applicantValues: {
        ...applicantsFilterPreferences.applicantValues,
        filters: {
          ...applicantsFilterPreferences.applicantValues.filters,
          ...{ [filterName]: value ? value : "" },
        },
        sort: {
          orderBy: "",
          order: "",
        },
        pagination: {
          page: "",
          pageSize: "",
        },
      },
    });
  };

  // Reset the filter panel when the submission changes
  useEffect(() => {
    if (submissionUpdate) resetApplicantsFilter();
    // eslint-disable-next-line
  }, [submissionUpdate]);

  return (
    <>
      {loading && <MuiLinearProgress />}
      <MuiFormControl>
        <Autocomplete
          id="applicantNameAutoComplete"
          key="applicant-names"
          getOptionLabel={(option) => option?.displayText ?? ""}
          value={selectedValues.applicant}
          label={t(`${applicantsFiltersTranslationKeys}.applicantName`)}
          onChange={(e, value) => {
            handleFilterValueUpdate("applicant", value || "", true);
            setApplicantsFilterPreferences({
              ...applicantsFilterPreferences,
              applicantValues: {
                ...applicantsFilterPreferences.applicantValues,
                filters: {
                  ...applicantsFilterPreferences.applicantValues.filters,
                  applicant: value,
                  applicantName: value?.value?.fullName || "",
                  applicantUniversityId: value?.value?.universityId || "",
                },
                sort: {
                  orderBy: "",
                  order: "",
                },
                pagination: {
                  page: "",
                  pageSize: "",
                },
              },
            });
          }}
          autoCompleteUrlPrefix={`/submissions/${selectedSubmission}/applicants/autocomplete?`}
          renderOption={(option, { inputValue }) => (
            <AutocompleteOption
              classes={commonClasses}
              option={option}
              inputValue={inputValue}
            />
          )}
        />
      </MuiFormControl>
      <MuiFormControl variant="outlined">
        <Autocomplete
          id="participatingDepartmentAutoComplete"
          key="participating-department"
          options={filterData.departments || []}
          value={selectedProgram ? { ...selectedProgram } : null}
          clientSide={true}
          label={t(
            `${applicantsFiltersTranslationKeys}.participatingDepartment`
          )}
          getOptionLabel={(option) =>
            option.name ? `${option.name} (${option.code})` : ""
          }
          onChange={(e, value) => {
            handleFilterValueUpdate(
              "departmentCode",
              (value && value.code) || "",
              true
            );
            setApplicantsFilterPreferences({
              ...applicantsFilterPreferences,
              applicantValues: {
                ...applicantsFilterPreferences.applicantValues,
                filters: {
                  ...applicantsFilterPreferences.applicantValues.filters,
                  departmentName: value?.name ? value.name : "",
                  departmentCode: value?.code ? value.code : "",
                },
                sort: {
                  orderBy: "",
                  order: "",
                },
                pagination: {
                  page: "",
                  pageSize: "",
                },
              },
            });
          }}
        />
      </MuiFormControl>
      <MuiFormControl>
        <Autocomplete
          id="applicationYear"
          key="application-year"
          options={filterData.applicationYears || []}
          value={
            selectedValues.applicationYear !== ""
              ? selectedValues.applicationYear
              : null
          }
          getOptionLabel={(option) => `${option}`}
          getOptionSelected={(op1, op2) => op1 === op2}
          clientSide={true}
          label={t(`${applicantsFiltersTranslationKeys}.applicationYear`)}
          onChange={(e, value) => {
            handleOnChange("applicationYear", value || "");
          }}
        />
      </MuiFormControl>

      <MuiFormControl>
        <Autocomplete
          id="acceptedYear"
          key="accepted-year"
          options={filterData.acceptedYears || []}
          value={
            selectedValues.acceptedYear !== ""
              ? selectedValues.acceptedYear
              : null
          }
          disabled={loading}
          getOptionLabel={(option) => `${option}`}
          getOptionSelected={(op1, op2) => op1 === op2}
          clientSide={true}
          label={t(`${applicantsFiltersTranslationKeys}.acceptedYear`)}
          onChange={(e, value) => {
            handleOnChange("acceptedYear", value || "");
          }}
        />
      </MuiFormControl>

      <MuiFormControl>
        <Autocomplete
          id="applicantType"
          key="applicant-type"
          options={filterData.applicantTypes || []}
          value={
            selectedValues.applicantType !== ""
              ? selectedValues.applicantType
              : null
          }
          getOptionLabel={(option) => t(traineeTypeDropDown[option])}
          getOptionSelected={(op1, op2) => op1 === op2}
          clientSide={true}
          label={t(`${applicantsFiltersTranslationKeys}.applicantType`)}
          onChange={(e, value) => {
            handleOnChange("applicantType", value || "");
          }}
        />
      </MuiFormControl>

      <div className={classes.checkBoxWrapper}>
        <MuiFormControlLabel
          classes={{
            label: classes.formControlLabel,
            root: classes.formControlLabelRoot,
          }}
          control={
            <MuiCheckbox
              name="newEntrant"
              disabled={loading}
              classes={{ root: classes.checkBoxRoot }}
              checked={selectedValues.newEntrant}
              onChange={(event) => {
                handleFilterValueUpdate(
                  "newEntrant",
                  event.target.checked,
                  true
                );
                setApplicantsFilterPreferences({
                  ...applicantsFilterPreferences,
                  applicantValues: {
                    ...applicantsFilterPreferences.applicantValues,
                    filters: {
                      ...applicantsFilterPreferences.applicantValues.filters,
                      newEntrant: event.target.checked || "",
                    },
                    sort: {
                      orderBy: "",
                      order: "",
                    },
                    pagination: {
                      page: "",
                      pageSize: "",
                    },
                  },
                });
              }}
            />
          }
          label={t(`${applicantsFiltersTranslationKeys}.newEntrant`)}
        />
      </div>

      <div className={classes.checkBoxWrapper}>
        <MuiFormControlLabel
          classes={{
            label: classes.formControlLabel,
            root: classes.formControlLabelRoot,
          }}
          control={
            <MuiCheckbox
              name="grantEligible"
              disabled={loading}
              classes={{ root: classes.checkBoxRoot }}
              checked={selectedValues.grantEligible}
              onChange={(event) => {
                handleFilterValueUpdate(
                  "grantEligible",
                  event.target.checked,
                  true
                );
                setApplicantsFilterPreferences({
                  ...applicantsFilterPreferences,
                  applicantValues: {
                    ...applicantsFilterPreferences.applicantValues,
                    filters: {
                      ...applicantsFilterPreferences.applicantValues.filters,
                      grantEligible: event.target.checked || "",
                    },
                    sort: {
                      orderBy: "",
                      order: "",
                    },
                    pagination: {
                      page: "",
                      pageSize: "",
                    },
                  },
                });
              }}
            />
          }
          label={t(`${applicantsFiltersTranslationKeys}.grantEligible`)}
        />
      </div>
      <div className={classes.checkBoxWrapper}>
        <MuiFormControlLabel
          classes={{
            label: classes.formControlLabel,
            root: classes.formControlLabelRoot,
          }}
          control={
            <MuiCheckbox
              name="isActive"
              disabled={loading}
              classes={{ root: classes.checkBoxRoot }}
              checked={selectedValues.isActive === false}
              onChange={(event) => {
                handleFilterValueUpdate(
                  "isActive",
                  !event.target.checked,
                  true
                );
                setApplicantsFilterPreferences({
                  ...applicantsFilterPreferences,
                  applicantValues: {
                    ...applicantsFilterPreferences.applicantValues,
                    filters: {
                      ...applicantsFilterPreferences.applicantValues.filters,
                      isActive: !event.target.checked || "",
                    },
                    sort: {
                      orderBy: "",
                      order: "",
                    },
                    pagination: {
                      page: "",
                      pageSize: "",
                    },
                  },
                });
              }}
            />
          }
          label={t(`${applicantsFiltersTranslationKeys}.isActive`)}
        />
      </div>

      <ResetFilter resetFn={resetApplicantsFilter} />
    </>
  );
};
