import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

// **** Material UI Components ****
import { FormControl as MuiFormControl } from "@material-ui/core";

// **** Custom Components and Services ****
import { ResetFilter } from "../Layout/FilterPanel/ResetFilter";
import {
  FacultyTrainingRoles,
  STORAGE_TYPE,
  STORAGE_NAMES,
} from "../../constants";
import { Autocomplete } from "../UI/Autocomplete/Autocomplete";
import { AutocompleteOption } from "../../Utils/Autocomplete";
import { useCommonStyles } from "../shared/common.styles";
/** Services */
import { useSelectedSubmission } from "../../services/selectedSubmissionService";
import { getFacultyFiltersOptionValues } from "../../services/facultyService";
import { useFacultyDemographicsContext } from "../../services/facultyDemographicsContext";
import { useBrowserStorage } from "../../services/StorageService/StorageHelper";
/** Context and Component to show the error on UI */
import { useAlertContext } from "@stanford-tds/as-components";

const initialFacultyFilterValues = {
  facultyName: "",
  degreeCode: "",
  rankCode: "",
  trainingRole: "",
  departmentCode: "",
};

// ************************* FacultyFilter *************************
export const FacultyFilter = ({ submissionUpdate }) => {
  const { t } = useTranslation();
  const classes = useCommonStyles();
  const { saveItem } = useBrowserStorage(STORAGE_TYPE.SESSION);
  // **** Selected Submission Id ****
  const [getSelectedSubmission] = useSelectedSubmission().value;
  const [getSelectedSubmissionName] = useSelectedSubmission().name;
  const selectedSubmission = getSelectedSubmission();
  const [degreeList, setDegreeList] = useState([]);
  const [rankList, setRankList] = useState([]);
  const [programList, setProgramList] = useState([]);
  const [trainingRoleList, setTrainingRoleList] = useState([]);

  const { setAlert, clearAlert } = useAlertContext();

  // **** Faculty Context Object ****
  const { facultyPreference, setFacultyPreference } =
    useFacultyDemographicsContext();

  // **** Filter Panel Form Fields Values ****
  const facultyFiltersSelectedValues =
    facultyPreference.facultyDemographics.filters;

  // Reset Filter Form Values
  const resetFacultyFilterValues = () => {
    saveItem(
      STORAGE_NAMES.TGDS_FACULTY_DASHBOARD_FILTERS,
      initialFacultyFilterValues
    );
    setFilterPreference({ ...initialFacultyFilterValues });
  };

  // ** On Page Load or Submission Update, reset Filter Values **
  useEffect(() => {
    if (selectedSubmission)
      getFacultyFiltersOptionValues(
        setDegreeList,
        setRankList,
        setProgramList,
        setTrainingRoleList,
        selectedSubmission,
        setAlert,
        clearAlert,
        getSelectedSubmissionName
      );
    // eslint-disable-next-line
  }, [
    facultyPreference.facultyDemographics.delete,
    facultyPreference.facultyDemographics.addFaculty,
    selectedSubmission,
  ]);

  // *** Helper Function to Update Context Object, on Field Value Update ***
  const setFilterPreference = (fieldUpdate) => {
    setFacultyPreference({
      ...facultyPreference,
      facultyDemographics: {
        ...facultyPreference.facultyDemographics,
        filters: {
          ...facultyPreference.facultyDemographics.filters,
          ...fieldUpdate,
        },
        sort: {
          orderBy: "",
          order: "",
        },
        pagination: {
          page: "",
          pageSize: "",
        },
      },
    });
  };

  // **** On Dropdown Value change, update search results ****
  const handleFilterValueUpdate = (fieldName, fieldValue, updateFilterData) => {
    const updatedFilterValues = {
      ...facultyPreference.facultyDemographics.filters,
      [fieldName]: fieldValue ?? "",
    };

    if (updateFilterData) {
      setFilterPreference(updatedFilterValues);
    }
  };

  useEffect(() => {
    if (submissionUpdate) resetFacultyFilterValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submissionUpdate]);

  return (
    <>
      <MuiFormControl variant="outlined">
        <Autocomplete
          id="facultyNameAutoComplete"
          key="faculty-names"
          getOptionLabel={(option) => option?.displayText ?? ""}
          value={facultyFiltersSelectedValues.facultyName}
          label={t("faculty.mainView.filters.primaryName")}
          onChange={(e, value) => {
            handleFilterValueUpdate("facultyName", value || "", true);
          }}
          autoCompleteUrlPrefix={`/submissions/${selectedSubmission}/faculty/autocomplete?context=faculty`}
          renderOption={(option, { inputValue }) => (
            <AutocompleteOption
              classes={classes}
              option={option}
              inputValue={inputValue}
            />
          )}
        />
      </MuiFormControl>

      <MuiFormControl variant="outlined">
        <Autocomplete
          id="degreeAutoComplete"
          key="degree-autocomplete"
          options={degreeList || []}
          getOptionLabel={(option) =>
            option.shortName ? option.shortName : ""
          }
          value={facultyFiltersSelectedValues.degreeCode}
          getOptionSelected={(op1, op2) => op1 === op2}
          clientSide={true}
          label={t("faculty.mainView.filters.degree")}
          onChange={(e, value) => {
            handleFilterValueUpdate("degreeCode", value || "", true);
          }}
        />
      </MuiFormControl>

      <MuiFormControl variant="outlined">
        <Autocomplete
          id="rankAutoComplete"
          key="rank-autocomplete"
          options={rankList || []}
          getOptionLabel={(option) => (option.name ? option.name : "")}
          value={facultyFiltersSelectedValues.rankCode}
          getOptionSelected={(op1, op2) => op1 === op2}
          clientSide={true}
          label={t("faculty.mainView.filters.rank")}
          onChange={(e, value) => {
            handleFilterValueUpdate("rankCode", value || "", true);
          }}
        />
      </MuiFormControl>

      <MuiFormControl variant="outlined">
        <Autocomplete
          id="programAutoComplete"
          key="program-autocomplete"
          options={programList || []}
          value={facultyFiltersSelectedValues.departmentCode}
          getOptionLabel={(option) =>
            option.name ? `${option.name} (${option.code})` : ""
          }
          getOptionSelected={(op1, op2) => op1 === op2}
          clientSide={true}
          label={t("faculty.mainView.filters.program")}
          onChange={(e, value) => {
            handleFilterValueUpdate("departmentCode", value || "", true);
          }}
        />
      </MuiFormControl>

      <MuiFormControl variant="outlined">
        <Autocomplete
          id="trainingAutocomplete"
          key="training-autocomplete"
          options={trainingRoleList || []}
          getOptionLabel={(option) => t(FacultyTrainingRoles[option])}
          value={t(facultyFiltersSelectedValues.trainingRole)}
          getOptionSelected={(op1, op2) => op1 === op2}
          clientSide={true}
          label={t("faculty.mainView.filters.trainingRole")}
          onChange={(e, value) => {
            handleFilterValueUpdate("trainingRole", value || "", true);
          }}
        />
      </MuiFormControl>
      <ResetFilter resetFn={resetFacultyFilterValues} />
    </>
  );
};
